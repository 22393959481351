import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import {LegalBody} from './styles'

const Wrapper = styled.div`
  margin: 0 auto;
`

const LegalPage = ({data}) => {
  const legalese = data.shopifyShopPolicy
  return (
    <>     
      <Wrapper>
        <LegalBody
			dangerouslySetInnerHTML={{ __html: legalese.body }}
		/>
      </Wrapper>
    </>
  )
}
export const query = graphql`
  query MyQuery ($type: String!){
	  shopifyShopPolicy(type: {eq: $type}) {
		title
		body
	  }
	}	 
`

export default LegalPage

