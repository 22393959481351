import styled from '@emotion/styled'

import { breakpoints } from '../../utils/styles'

export const LegalBody = styled.div`
	margin-left: 107px;
	margin-right: 109px;
	background: #eee;
	margin-bottom: 40px;
	margin-top: 40px;
	@media (max-width: ${breakpoints.m}px){
		margin-left: 0;
		margin-right: 0;
	}
`